<template>
  <div>
    <img src="@/assets/img/topBG.jpg" alt="" />
    <div style="height: 25vw;"></div>
    <div>
<!--      <span>{{userinfo}}</span>-->
    </div>
    <div class="btn-f">
      <button class="btn" @click="Logout">退出登录</button>
    </div>
  </div>
</template>

<script>
import { DoLogin, getUserList } from '@/api/login'
import Cookies from "js-cookie";
import { Notify } from "vant";
import {
  setToken,
  logout,
  getOpenId,
  isBindUser,
  getToken, getUserInfo, doLogout, currentUser, setCurrentUser
} from "../../utils/user";
import { isBind } from "../../api/login";

export default {
  data() {
    return {
      role: [],
      radio: "",
      userinfo: []
    };
  },
  mounted() {
  },
  created() {
    // this.role = this.$store.state.role
    // if (this.role === null || this.role.length === 0) {
    //   getUserList({ 'openid': getOpenId() }).then(res => {
    //     var role = res.data;
    //     if (role) {
    //       this.$store.commit("savePath", role);
    //       this.role = this.$store.state.role
    //     }
    //   })
    // }
    this.userinfo = getUserInfo()
  },
  methods: {
    // 退出登录
    Logout() {
      doLogout()
    }
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
}

.icon {
  color: #5380ff;
}

/* 选择卡片 */
.modul {
  border-radius: 15px;
  background-color: #f6f8fa;
  width: 85%;
  margin-top: 1rem;
  margin-left: 50%;
  transform: translate(-50%);
  padding: 0.5rem;

  img {
    width: 5%;
    margin-bottom: -0.2rem;
    margin-right: 0.5rem;
  }

  div {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

/* 登录按钮 */
.btn {
  width: 90%;
  height: 3rem;
  margin-top: 3rem;
  border: 0;
  border-radius: 30px;
  color: white;
  background: repeating-linear-gradient(to left, #2357f0, #3db1ea);
}

.btn-f {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 4px;
}

.name {
  margin: 0;
  font-weight: bold;
  color: #555555;
  font-size: 3.7vw;
}

.school {
  color: #999999;
}
</style>
